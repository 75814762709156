<template>
  <button class="button__close">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.615"
      height="15.615"
      viewBox="0 0 15.615 15.615"
    >
      <g transform="translate(-130.052 -20.218)">
        <path
          d="M132.113,20.842a1.016,1.016,0,0,0-1.437,1.437l12.93,12.93a1.016,1.016,0,1,0,1.437-1.437Z"
          transform="translate(0 0)"
          stroke="#00439c"
          fill="#fff"
          stroke-width="0.5"
        />
        <path
          d="M130.676,33.772a1.016,1.016,0,0,0,1.437,1.437l12.93-12.93a1.016,1.016,0,1,0-1.437-1.437Z"
          transform="translate(0 0)"
          stroke="#00439c"
          fill="#fff"
          stroke-width="0.5"
        />
      </g>
    </svg>
  </button>
</template>

<style lang="scss" scoped>
.button__close {
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
}
</style>
